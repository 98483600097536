import {
  Enum_Questiontree_Treetype,
  getChecksum,
  notNull,
  useMyVisitLazyQuery,
  useQuestionTreesLazyQuery,
} from '@cyren/common-lib';
import produce from 'immer';
import { isEmpty, keys } from 'lodash';
import { useEffect } from 'react';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';

import { useNavigate } from 'react-router';
import { convSurvey, convTree, convVisit } from '../gql-tools/utils/convert';
import { PaReportState } from '../patient/hooks/use-report';
import { PaSurveyState } from '../patient/hooks/use-survey-state';
import { Keys, PaDataRepoStateT, QuestionTreeType } from '../patient/patient-types';
import { usePaSurveyParams } from './use-pa-survey-params';

const pageSize = 200000;

export const PaDataRepoState = atom<PaDataRepoStateT>({
  key: 'DataRepoState',
  default: {
    trees: [],
    questions: [],
    translations: [],
    doctorsTerms: [],
    userNoteTrans: [],
    survey: null,
    org: null,
  },
});

export function useData({
  initData,
  disableRedirectOnInvalidReport,
}: { initData?: boolean; disableRedirectOnInvalidReport?: boolean } = {}) {
  const params = usePaSurveyParams();
  const nav = useNavigate();

  const { orgId, shortId, adhocId, referralMessageId } = params;

  const baseUrl = `/s/${orgId}/${shortId}`;

  const [dataState, setDataState] = useRecoilState(PaDataRepoState);
  const setSurveyState = useSetRecoilState(PaSurveyState);
  const setReportState = useSetRecoilState(PaReportState);

  const { visit: visitFrag, trees, translations, survey, questions, doctorsTerms } = dataState;

  // TODO: optimize load survey in 1 api call
  const [fetchMyVisit, { data: dataSurveys, loading: loadingSurveys }] = useMyVisitLazyQuery({
    onCompleted: () => {
      // console.log('fetch my visit comp');
    },
  });

  // REDIRECT USER TO FINAL WHEN STATUS = REPORTED | ARCHIVED
  const status = visitFrag?.status;
  const archived = visitFrag?.archived;

  useEffect(() => {
    if (disableRedirectOnInvalidReport) return;

    if (!isEmpty(adhocId)) {
      // if this is an adhoc form, don't navigate them to the main finished screen, since
      // adhoc forms can be request event after the main survey has been completed
      return;
    }
    if (status === 'reported' || archived) {
      nav(`${baseUrl}/finished`);
    }
  }, [disableRedirectOnInvalidReport, status, archived]);

  const [fetchTrees, { data: dataTrees, loading: loadingTrees }] = useQuestionTreesLazyQuery();

  useEffect(() => {
    async function run() {
      if (shortId == null) return;

      const errors: string[] = [];

      const { data: visitData, error } = await fetchMyVisit({
        variables: {
          input: {
            shortId,
            referralMessageId,
          },
        },
      });

      if (error) {
        // TODO log and report an error
        errors.push('There was an error loading data');

        setDataState((st) => {
          return {
            ...st,
            errors,
            init: true,
          };
        });
        return;
      }

      // DECRYPT DATA
      const surveyData = convSurvey(visitData?.myVisit?.survey?.data);
      const visit = convVisit(visitData?.myVisit?.patientVisit?.data);

      // VERIFY CHECKSUM
      const { checksum, reportState } = visit?.surveyState || {};
      const nChecksum = getChecksum(reportState);

      if (nChecksum !== checksum) {
        // eslint-disable-next-line
        console.log('report checksum mismatch');

        // TODO log and report checksum mismatch
        // eslint-disable-next-line
        // console.log('checksum', checksum);
        // eslint-disable-next-line
        // console.log('nChecksum', nChecksum);
      }

      if (!surveyData) return;

      const firstSurveyId = surveyData?.id;

      if (firstSurveyId == null) return;

      const qsTreeResp = fetchTrees({
        variables: {
          filters: {
            survey: {
              id: {
                eq: firstSurveyId,
              },
            },
            enabled: {
              eq: true,
            },
            treeKey: {
              in: surveyData?.questionTreeKeys || [],
            },
            treeType: {
              eq: Enum_Questiontree_Treetype.Step,
            },
          },
          pagination: {
            pageSize,
          },
        },
      });

      const [treesData] = await Promise.all([qsTreeResp]);

      const nTrees = treesData.data?.questionTrees?.data.map(convTree).filter(notNull) || [];
      const org = surveyData?.organization;

      if (!surveyData) return;

      const { questionTreeKeys } = surveyData || {};
      const nTreeKeys = questionTreeKeys?.filter((f) => {
        const tree = nTrees.find((item) => item.treeKey === f && item.enabled);
        return !!tree;
      });

      const nSurvey = {
        ...surveyData,
        questionTreeKeys: nTreeKeys || [],
      };

      setSurveyState(
        produce(
          visit?.surveyState?.surveyState || {
            adaptedTreeKeys: [],
          },
          (draft) => {
            keys(visit?.surveyState?.surveyState).forEach((key) => {
              // @ts-ignore
              draft[key] = visit?.surveyState?.surveyState?.[key];
            });
          },
        ),
      );

      setReportState(
        produce(
          visit?.surveyState?.reportState || {
            globalAnswerMap: {},
            treeStates: [],
            nextTreeKeys: [],
            presentIllness: [],
          },
          (draft) => {
            keys(visit?.surveyState?.reportState).forEach((key) => {
              // @ts-ignore
              draft[key] = visit?.surveyState?.reportState?.[key];
            });
          },
        ),
      );

      setDataState((st) => {
        return {
          ...st,
          visit,
          init: true,
          survey: nSurvey,
          org,
          trees: nTrees,
          errors,
        };
      });

      /* const items = surveyResp.data.map(convQs) || []; */
      /* const questions = data?.questions?.data.map(convQs) || []; */

      /* const items = data?.questionTrees?.data.map(convTree) || []; */
      /* const nItems = items.filter(notNull); */
    }

    if (initData && dataState.survey?.short_id !== shortId) {
      run();
    }
  }, [shortId, initData]);

  const isLoading = loadingSurveys || loadingTrees;

  // console.log('loadingTrans', loadingTrans);
  // console.log('loadingTrees', loadingTrees);
  // console.log('loadingSurvey', loadingSurvey);
  // console.log('isLoading', isLoading);

  function getSurvey() {
    return survey;
  }

  function getTrees(searchKey?: string) {
    if (!searchKey) return trees || [];
    return trees?.filter((tree) => tree.treeKey.includes(searchKey)) || [];
  }

  function getTreeByKey(treeKey: string) {
    return trees?.find((tree) => tree.treeKey === treeKey) || null;
  }

  function getTreesByType(treeType: Enum_Questiontree_Treetype | null) {
    return trees?.filter((tree) => tree.treeType === treeType) || [];
  }

  function getQuestionByKey(key?: string | null) {
    if (key == null) return null;
    return questions?.find((q) => q.questionKey === key);
  }

  function getQsById(id?: string | null) {
    if (id == null) return null;
    return questions?.find((q) => q.id === id);
  }

  function getReportLabel(key?: string) {
    if (!key) return undefined;
    const question = getQuestionByKey(key);
    return question?.reportLabel;
  }

  function getQuestionsByKeys(qsKeys: string[]) {
    return qsKeys?.map((key) => getQuestionByKey(key))?.filter(notNull);
  }

  function getTransByKey(key: string) {
    return translations?.find((q) => q.key === key);
  }

  function getTranssByKeys(transKeys: string[]) {
    return transKeys.map((key) => getTransByKey(key)).filter(notNull);
  }

  function getTermByKey(key: string) {
    return doctorsTerms?.find((q) => q.key === key);
  }

  function getTermsByKeys(tKeys: Keys) {
    return tKeys.map((key) => getTermByKey(key)).filter(notNull);
  }

  function updateTree(nTree?: QuestionTreeType | null) {
    if (!nTree) {
      return;
    }

    setDataState((st) => {
      return produce(st, (draft) => {
        draft.trees =
          trees?.map((tree) => {
            if (tree.treeKey === nTree.treeKey) {
              return nTree;
            }
            return tree;
          }) || null;
      });
    });
  }

  // console.log('dataState?.questions', dataState?.questions);
  // console.log('dataState?.translations', dataState?.translations);

  return [
    {
      dataState,
      isLoading,
      loadingSurvey: loadingSurveys,
      loadingTrees,

      dataSurveys,
      dataTrees,
      surveyId: survey?.id,
    },
    {
      updateTree,
      setDataState,
      getSurvey,
      getTreeByKey,
      getTrees,
      getTreesByType,
      getQuestionByKey,
      getTermsByKeys,
      getTermByKey,
      getQsById,
      getReportLabel,
      getTransByKey,
      getTranssByKeys,
      getQuestionsByKeys,
    },
  ] as const;
}
